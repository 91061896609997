import React from 'react';
import Proptypes from 'prop-types';

const PortfolioItemFive = ({className,title,thumb,category,desc,instagram}) => {
    return (
        <div className={`portfolio portfolio_style--1 ${className}`}>
            <div className="poss_relative">
                <div className="thumb">
                    <img src={thumb} alt={title}/>
                </div>
                <a href={instagram} target="_blank" className="d-block port-overlay-info with-caption position-left font-playfair">
                    <div className="hover-action">
                        <h3 className="post-overlay-title"><a>{title}</a></h3>
                        <div className="category">{category}</div>
                        <div className="mt-4 category portfolio_description">{desc}</div>
                    </div>
                </a>
            </div>
        </div>
    );
};

PortfolioItemFive.propTypes = {
    className: Proptypes.string
}

export default PortfolioItemFive;
