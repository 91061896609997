import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const AboutMinimalAgency = () => {
    return (
        <div className="brook-about-area customize-bg--01 ptb--130 ptb-md--80 ptb-sm--60">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="about-content text-center wow move-up">
                            <h6 className="heading heading-h6 theme-color secondary-font font-bold">Services</h6>
                            <div className="bk-separator--10"/>
                            <h2 className="heading heading-h2 playfair-font line-height-1-62 font-700">
                                Bringing AI <span className="theme-color">Closer</span> to You
                            </h2>
                            <p>
                                AI가 당신에게 더 가까워 지도록, FLABS AI
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutMinimalAgency;
