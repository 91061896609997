import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import PageContainer from "container/page";
import Header from "components/header/HeaderEight";
import PopupSearch from "components/popup/PopupSearch";
import PopupMobileMenu from "components/popup/PopupMobileMenu";
import Breadcrumb from "components/breadcrumb/BreadcrumbTwo";
import breadcrumbBg from "assets/img/bg/bg-image-gpt.jpg";
import WorkArea from "container/work/WorkCreator";
import SkillsArea from "container/skills/SkillsOne";
import FlexibleImageSlider from "container/flexible-image-slider/FlexibleImageSliderCreator";
import Services from "container/service/ServiceCreator";
import Testimonial from "container/testimonial/elements/TestimonialOne";
import ClientLogo from "container/client-logo/homes/ClientLogoOne";
import CallToAction from "container/call-to-action/pages/CallToActionOne";
import FooterOne from "container/footer/FooterOne";

const AboutCreator = () => {
    return (
        <Fragment>
            <Helmet>
                <title>FLABS AI || Experts of [GPTs]</title>
            </Helmet>

            <PageContainer classes="template-color-1 template-font-1" revealFooter={false}>
                <Breadcrumb
                    title={`Experts of [GPTs]`}
                    bgImg={breadcrumbBg}
                />
                <WorkArea/>
                {/*<SkillsArea/>*/}
                <FlexibleImageSlider/>
                <Services/>
                {/*<Testimonial/>*/}
                {/*<ClientLogo/>*/}
                <CallToAction bgColor={"#1F3319"}/>
                {/*<FooterOne/>*/}
            </PageContainer>
        </Fragment>
    );
};

export default AboutCreator;