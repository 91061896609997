 import React from 'react';
 import { createRoot } from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "./assets/css/ionicons.min.css";
import "./assets/css/linea-font.css";
import './assets/scss/style.scss';

 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getAnalytics } from "firebase/analytics";
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries

 // Your web app's Firebase configuration
 // For Firebase JS SDK v7.20.0 and later, measurementId is optional
 const firebaseConfig = {
  apiKey: "AIzaSyDpFy5EL-3jmEGIzISi2WsElLx7vGPL6d0",
  authDomain: "flabs-ai.firebaseapp.com",
  projectId: "flabs-ai",
  storageBucket: "flabs-ai.appspot.com",
  messagingSenderId: "731600508690",
  appId: "1:731600508690:web:47400fc5c084f85c31c5cb",
  measurementId: "G-PFNHQZLMQX"
 };

 // Initialize Firebase
 const app = initializeApp(firebaseConfig);
 const analytics = getAnalytics(app);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App  />);