import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import GradationItem from "components/gradation";

const ServiceModel = () => {
    return (
        <div className="brook-gradation-area pb--150 pb-md--80 pb-sm--60 bg_color--1 basic-thine-line">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-center">
                            <h5 className="heading heading-h5 red-color">Procedure</h5>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h3">진행절차</h3>
                            {/*<a className="d-inline-block mt-3" id="pdfDownload" href="./flabsAI_visualizing.pdf" download="flabsAI_visualizing.pdf">제안서 다운로드</a>*/}
                        </div>

                        <div className="bk-gradation mt--30 mt_sm--5">
                            {contents.map(content => (
                                <GradationItem
                                    key={content.id}
                                    count={content.id}
                                    heading={content.heading}
                                    content={content.text}
                                    color={"#E30022"}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const contents = [
    {
        id: 1,
        heading: "협의 및 계약",
        text: "클라이언트의 주문 제작 요청사항을 검토하고, 제작 기간 및 비용 산정 후 협의 과정을 거쳐 계약을 진행합니다"
    },
    {
        id: 2,
        heading: "모델 제작",
        text: "클라이언트와의 소통을 바탕으로 제작 기간에 맞춰 요청된 비주얼의 모델을 제작합니다."
    },
    {
        id: 3,
        heading: "피드백 및 수정작업",
        text: "제작된 결과물을 바탕으로 계약된 횟수에 한해 모델의 비주얼라이징을 피드백 및 수정할 수 있습니다"
    }
]

export default ServiceModel;
