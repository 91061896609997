import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import brook from "data/brook.json";

import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/SideHeader";
import SliderMinimalAgency from "container/slider/SliderMinimalAgency";
import About from "container/about-us/AboutMinimalAgency";
import Portfolios from "container/portfolio/homes/PortfolioFive";
import ClientLogo from "container/client-logo/homes/ClientLogoThree";
import PopupMenu from "components/header/elements/popup";
import Footer from "container/footer/FooterFour";
import ChannelService from "../../ChannelService";

const HomeMinimalAgency = () => {

    //2. 설치하기
    ChannelService.loadScript();

    //3. 부트하기
    ChannelService.boot({
        pluginKey: "4baa0973-d82d-46eb-bc63-f6dc7f38f2b5",
    });

    return (
        <Fragment>
            <Helmet>
                <title>{`플랩스 AI || FLABS AI`}</title>

                <meta name="description" content="FLABS AI || Bringing AI Closer to You" data-react-helmet="true"/>
                <meta name="keywords" content="플랩스, 플랩스 ai, flabs, flabs ai, AI, virtual, 인공지능, 가상, 버추얼, 버추얼휴먼, 가상인플루언서, 가상모델, 가상인간, 모델, 기획, 예술, 인스타그램, 틱톡, 유튜브" data-react-helmet="true"/>
                <meta name="naver-site-verification" content="b01a66dd959db3345146019606c6ffeb62c14f12"/>
                <meta property="og:type" content="website" data-react-helmet="true"/>
                <meta property="og:title" content="플랩스 AI || FLABS AI" data-react-helmet="true"/>
                <meta property="og:site_name" content="플랩스 AI || FLABS AI" data-react-helmet="true"/>
                <meta property="og:description" content="FLABS AI || Bringing AI Closer to You" data-react-helmet="true"/>
                <meta property="og:image" content="https://flabs-ai.web.app/static/media/FLABS.7ed99799942b726044fd.png" data-react-helmet="true"/>
                <meta property="og:url" content="https://flabs.ai" data-react-helmet="true"/>

                <meta name="twitter:title" content="플랩스 AI || FLABS AI" data-react-helmet="true"/>
                <meta name="twitter:card" content="image" data-react-helmet="true"/>
                <meta name="twitter:description" content="FLABS AI || Bringing AI Closer to You" data-react-helmet="true"/>
                <meta name="twitter:image" content="https://flabs-ai.web.app/static/media/FLABS.7ed99799942b726044fd.png" data-react-helmet="true"/>

                <meta property="og:image:width" content="1200" data-react-helmet="true"/>
                <meta property="og:image:height" content="630" data-react-helmet="true"/>

                <meta name="naver" content="nosublinks"/>
                <meta name="google" content="nosublinks"/>
            </Helmet>
            <LayoutDefault className="template-color-19 template-font-4" revealFooter={false}>
                <Header/>
                {/*<PopupMenu/>*/}
                <SliderMinimalAgency/>
                <About/>
                <Portfolios/>
                {/*<ClientLogo/>*/}
                <Footer/>
            </LayoutDefault>
        </Fragment>
    );
};

export default HomeMinimalAgency;