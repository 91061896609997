import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ListItem from "components/list";

const WorkModel = ({className}) => {
    return (
        <div className={`brook-what-do-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1 ${className}`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-start">
                            <h5 className="heading heading-h5 red-color">What we do</h5>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h3 ">기업, 기관 및 개인의 "META [　　　]"을 제작합니다</h3>
                        </div>
                    </Col>
                </Row>

                <Row className="mt--40 mt_md--20 mt_sm--5 bk-list">
                    <Col lg={2}>
                        <div className="what-do mt--40 mt_md--5 mt_sm--5">
                            <div className="basic-separator line-3"/>
                        </div>
                    </Col>

                    <Col xl={4} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                color={"red-color"}
                                text={'합리적인 선택'}
                                content={"'모델,아티스트,캐릭터' 등 [　　]안에 무엇이든 넣어보세요. AI 메타휴먼은 기존 VFX, SFX 기업들이 안고 있던 고비용의 문제와 기존 모델 산업이 안고있던 사생활 리스크를 합리적으로 해결하는 완벽한 선택입니다"}
                            />
                        </div>
                    </Col>

                    <Col xl={{span: 4, offset:1}} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                color={"red-color"}
                                text={'제작 범위'}
                                content={"FLABS AI의 작업물은 이미지 제작에만 국한되어 있지 않습니다. 이미지는 물론이고 음성, 영상, 포스터 및 컨텐츠 제작까지 높은 기술력으로 빠르고 만족스러운 결과물을 자신합니다"}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkModel;
