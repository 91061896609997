import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import GradationItem from "components/gradation";

const ServiceCreator = () => {
    return (
        <div className="brook-gradation-area pb--150 pb-md--80 pb-sm--60 bg_color--1 basic-thine-line">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-center">
                            <h5 className="heading heading-h5 theme-color">Procedure</h5>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h3">진행절차</h3>
                        </div>

                        <div className="bk-gradation mt--30 mt_sm--5">
                            {contents.map(content => (
                                <GradationItem
                                    key={content.id}
                                    count={content.id}
                                    heading={content.heading}
                                    content={content.text}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const contents = [
    {
        id: 1,
        heading: "인터뷰 및 계약",
        text: "클라이언트와 FLABS AI간의 인터뷰를 진행합니다. 서로에게 유익한 협업이라고 판단되면 계약을 진행합니다"
    },
    {
        id: 2,
        heading: "자료취합",
        text: "클라이언트는 GPTs 제작에 필요한 자료를 제출하고, FLABS AI는 받은 자료를 취합 및 정리합니다"
    },
    {
        id: 3,
        heading: "GPTs 제작 및 테스트",
        text: "취합 및 정리된 자료를 바탕으로 GPTs를 제작하고, 상용 수준까지 Test 이후 런칭합니다"
    }
]

export default ServiceCreator;
