import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ListItem from "components/list";

const WorkArtist = ({className}) => {
    return (
        <div className={`brook-what-do-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1 ${className}`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-start">
                            <h5 className="heading heading-h5 black-color">Who We Are</h5>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h3">당신과 AI가 더 가까워지도록</h3>
                        </div>
                    </Col>
                </Row>

                <Row className="mt--40 mt_md--20 mt_sm--5 bk-list">
                    <Col lg={2}>
                        <div className="what-do mt--40 mt_md--5 mt_sm--5">
                            <div className="basic-separator line-3"/>
                        </div>
                    </Col>

                    <Col xl={4} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                color={"black-color"}
                                text={'AI시대의 친절한 동반자'}
                                content={"FLABS AI는 모든 사람들이 인공지능을 보다 쉽고 친밀하게 사용할 수 있게 돕는 것을 최종목표이자 사명으로 여기고 있습니다. 이를 위해 문제를 발견하고, 해결책을 제작하고 있습니다"}
                            />
                        </div>
                    </Col>

                    <Col xl={{span: 4, offset:1}} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                color={"black-color"}
                                text={'그래서 우리는'}
                                content={"매일 새로운 AI를 소개하고, GPT를 편하게 사용할 수 있도록 서비스를 만들고, 실제와 같은 메타휴먼을 제작함으로 인공지능과 사람들 사이의 거리를 좁히기 위해 노력하고 있습니다"}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkArtist;
