import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import PageContainer from "container/page";
import Header from "components/header/HeaderEight";
import PopupSearch from "components/popup/PopupSearch";
import PopupMobileMenu from "components/popup/PopupMobileMenu";
import Breadcrumb from "components/breadcrumb/BreadcrumbTwo";
import breadcrumbBg from "assets/img/bg/bg-image-creator-1.png";
import WorkArea from "container/work/WorkArtist";
import SkillsArea from "container/skills/SkillsOne";
import FlexibleImageSlider from "container/flexible-image-slider/FlexibleImageSliderArtist";
import Services from "container/service/ServiceArtist";
import Testimonial from "container/testimonial/elements/TestimonialOne";
import ClientLogo from "container/client-logo/homes/ClientLogoOne";
import CallToAction from "container/call-to-action/pages/CallToActionOne";
import FooterOne from "container/footer/FooterOne";

const AboutArtist = () => {
    return (
        <Fragment>
            <Helmet>
                <title>FLABS AI || About FLABS AI</title>
            </Helmet>

            <PageContainer classes="template-color-1 template-font-1" revealFooter={false}>
                <Breadcrumb
                    title={`FLABS AI`}
                    bgImg={breadcrumbBg}
                />
                <WorkArea/>
                {/*<SkillsArea/>*/}
                <FlexibleImageSlider/>
                {/*<Services/>*/}
                {/*<Testimonial/>*/}
                {/*<ClientLogo/>*/}
                <CallToAction bgColor={"#000000"}/>
                {/*<FooterOne/>*/}
            </PageContainer>
        </Fragment>
    );
};

export default AboutArtist;