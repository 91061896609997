import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ListItem from "components/list";

const WorkCreator = ({className}) => {
    return (
        <div className={`brook-what-do-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1 ${className}`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-start">
                            <h5 className="heading heading-h5 theme-color">What we do</h5>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h3">GPTs로 또 하나의 당신을 만드세요</h3>
                        </div>
                    </Col>
                </Row>

                <Row className="mt--40 mt_md--20 mt_sm--5 bk-list">
                    <Col lg={2}>
                        <div className="what-do mt--40 mt_md--5 mt_sm--5">
                            <div className="basic-separator line-3"/>
                        </div>
                    </Col>

                    <Col xl={4} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                text={'GPTs는 쉬지 않습니다'}
                                content={"당신의 컨디션과 상관없이 또 다른 당신인 GPTs를 FLABS AI와 함께 제작하세요. GPTs는 지치지도 쉬지도 잠들지도 않고 또 하나의 당신이 되어 24시간 365일 당신을 위해 일할 것입니다."}
                            />
                        </div>
                    </Col>

                    <Col xl={{span: 4, offset:1}} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                text={'확연히 다른 품질'}
                                content={"수준높은 프롬프트 엔지니어링을 기반으로 타사와는 차원이 다른 고품질의 GPTs를 제작합니다. 뿐만 아니라 자사 서비스인 플루밍, 메타스트리밍과 GPTs를 연결하여 보다 실제적인 경험을 선사합니다"}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkCreator;
